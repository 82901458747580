import React from 'react';
import './App.css';
import Header from './layouts/Header';
import Slider from './layouts/Slider';
import Footer from './layouts/Footer';
import Domaines from './layouts/Domaines';
import Services from './layouts/Services';

function App() {
  
  return (
    <div className="App">


      <Header />
      <Slider />
      <Domaines />
      <Services />
      <Footer />

      {/* Autres éléments de votre application */}
    </div>
  );
}

export default App;