import React from 'react';

function Footer() {
  return (
    <footer className="footer-base">
      <div className="container content">
          <div className="row">
              <div className="col-md-4">
                  <img className="logo" src="/vendor/images/logo.png" alt="logo" />
                  <hr className="space s" />
                  <hr className="space s" />
                  <div className="btn-group social-group btn-group-icons">
                      <a target="_blank" href="#" data-social="share-linkedin" data-toggle="tooltip" data-placement="top" title="LinkedIn">
                          <i className="fa fa-linkedin text-s circle"></i>
                      </a>
                      <a target="_blank" href="#" data-social="share-facebook" data-toggle="tooltip" data-placement="top" title="Facebook">
                          <i className="fa fa-facebook text-s circle"></i>
                      </a>
                      <a target="_blank" href="#" data-social="share-twitter" data-toggle="tooltip" data-placement="top" title="X">
                          <i className="fa fa-twitter text-s circle"></i>
                      </a>
                      <a target="_blank" href="#" data-social="share-youtube" data-toggle="tooltip" data-placement="top" title="Youtube">
                          <i className="fa fa-youtube text-s circle"></i>
                      </a>
                      
                  </div>
              </div>
              <div className="col-md-4">
                  <h3 className="text-black text-uppercase">COORDONNÉES</h3>
                  <hr className="space space-30" />
                  <ul className="fa-ul text-s ul-squares">
                      <li>contact@i-kem.com</li>
                      <li>+45 9370 5756</li>
                  </ul>
              </div>
              <div className="col-md-4">
                  <h3 className="text-black text-uppercase">RESSOURCES UTILES</h3>
                  <hr className="space space-30" />
                  <div className="footer-menu text-s">
                      <a href="#">NEWSLETTER</a>
                      <a href="#">À PROPOS DE NOUS</a>
                      <a href="#">S'ABONNER</a>
                      <a href="#">CONTACT</a>
                  </div>
              </div>
          </div>
          <hr className="space hidden-sm" />
          <div className="row copy-row">
              <div className="col-md-12 copy-text">
              Copyright © iKEM GROUP 2023
              </div>
          </div>
      </div>
  </footer>
  );
}

export default Footer;