import React from 'react';

const paragraph_style = {
    textAlign: 'justify',
    fontWeight: 400,
    fontSize: '1.5rem',
    fontFamily: "'Coda', sans-serif"
  };

const paragraph_2_style = {
    textAlign: 'justify',
    color: '#000000',
    fontWeight: 400,
    fontSize: '1.7rem',
    fontFamily: "'Coda', sans-serif"

};

const paragraph_3_style = {
    fontWeight: 600,
    fontSize: '1.88rem',
    fontFamily: "'Coda', sans-serif"

};

const paragraph_4_style = {
    fontSize: '80%',
    fontFamily: "'Coda', sans-serif",
    maxWidth: '80%',
    wordWrap: 'break-word',
    textAlign: 'center',
};


const Domaines = () => {
  return (
    <div className="section-empty section-over">
        <div className="container content">
            <div className="row">
                <div className="col-md-8 col-slm-6 col-sm-12">
                    <div className="flexslider carousel outer-navs" data-options="minWidth:225,itemMargin:60,numItems:2,controlNav:false,directionNav:true">
                        <ul className="slides">
                            <li>
                                <div className="advs-box advs-box-top-icon-img boxed-inverse text-left">
                                    <a className="img-box lightbox img-scale-up" href="#">
                                        <span><img src="vendor/images/gallery/image-1.jpg" alt="Bétons" /></span>

                                    </a>
                                    <div className="advs-box-content">
                                        <h3>ADJUVANTS POUR BÉTON</h3>
                                        <p style={paragraph_style}>
                                            La gamme la plus récente de nos adjuvants destinés au béton prêt à l'emploi propose des solutions pour réaliser un béton à la fois solide, durable et maniable.
                                        </p>
                                        <a href="#" className="btn btn-xs btn-border">Nos solutions béton</a>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="advs-box advs-box-top-icon-img boxed-inverse text-left">
                                    <a className="img-box lightbox img-scale-up" href="#">
                                        <span><img src="/vendor/images/gallery/image-2.jpg" alt="Ciments" /></span>
                                    </a>
                                    <div className="advs-box-content">
                                        <h3>ADDITIFS POUR CIMENTS</h3>
                                        <p style={paragraph_style}>
                                            Les additifs que nous offrons jouent un rôle fondamental dans la production de ciment de qualité supérieure, tout en améliorant la productivité, l'efficacité et la rentabilité.
                                        </p>
                                        <a href="#" className="btn btn-xs btn-border">Nos solutions ciments</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-4 col-slm-6 col-sm-12 boxed white">
                    <h2 className="text-color" style={paragraph_3_style}>Un demi-siècle d'engagement à innover au profit de notre clientèle</h2>
                    <hr className="space m" />
                    <p className="text-s" style={paragraph_style}>
                        iKEM développe, fabrique et offre des solutions chimiques favorisant la conception de matériaux durables pour la construction.
                    </p>
                    <br></br>
                    <table className="grid-table border-table text-left grid-table-sm-12">
                        <tbody>
                        <tr>
                            <td>
                                <p style={paragraph_4_style}>PROJETS</p>
                                <div className="icon-box counter-box-icon">
                                    <div className="icon-box-cell">
                                        <i className="im-crane text-xl text-color"></i>
                                    </div>
                                    <div className="icon-box-cell">
                                        <label className="counter text-l" data-speed="5000" data-to="110">110</label>
                                        
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p  style={paragraph_4_style}>PARTENAIRES</p>
                                <div className="icon-box counter-box-icon">
                                    <div className="icon-box-cell">
                                        <i className="im-engineering text-xl  text-color"></i>
                                    </div>
                                    <div className="icon-box-cell">
                                        <label className="counter text-l" data-speed="5000" data-to="90">90</label>
                                    </div>
                                </div>
                                
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p style={paragraph_4_style}>BIENFACTORY <br></br></p>
                                <div className="icon-box counter-box-icon">
                                    <div className="icon-box-cell">
                                        <i className="im-factory text-xl  text-color"></i>
                                    </div>
                                    <div className="icon-box-cell">
                                        <label className="counter text-l" data-speed="5000" data-to="3">3</label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p style={paragraph_4_style}>LABORATOIRES</p>
                                <div className="icon-box counter-box-icon">
                                    <div className="icon-box-cell">
                                        <i className="im-flask text-xl text-color"></i>
                                    </div>
                                    <div className="icon-box-cell">
                                        <label className="counter text-l" data-speed="5000" data-to="3">3</label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            
            <hr className="space" />

            <div className="row ">
                <div className="col-md-12" style={paragraph_2_style}>
                    <h2>Applications et Usages</h2>
                    <p style={paragraph_2_style}>
                    iKEM est pleinement dévouée à la recherche, à la fabrication, et à la mise en avant de solutions chimiques, spécialement élaborées dans le but de promouvoir la création de matériaux durables destinés à être employés dans le domaine de la construction. 
                    Les adjuvants destinés au béton, ainsi que les additifs pour ciments, occupent une place centrale dans l'industrie de la construction, trouvant des applications variées dans des domaines spécifiques, notamment :
                    </p>
                    
                    <br></br>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="list-group accordion-list">
                                <div className="list-group-item">
                                    <a> Béton prêt à l'emploi</a>
                                </div>
                                <div className="list-group-item">
                                    <a>Béton préfabriqué</a>
                                </div>
                                <div className="list-group-item">
                                    <a>Revêtements de surface</a>
                                </div>
                                <div className="list-group-item">
                                    <a>Ciments</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="list-group accordion-list">
                                <div className="list-group-item">
                                    <a>Béton Architectonique</a>
                                </div>
                                <div className="list-group-item">
                                    <a>Tunnels et infrastructures souterraines</a>
                                </div>
                                <div className="list-group-item">
                                    <a>Restauration de Structures</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Domaines;