import React from 'react';

const Header = () => {
    return (
        <header className="fixed-top scroll-change logo-left" data-menu-height="139">
            <div className="navbar navbar-default navbar-fixed-top" role="navigation">
                <div className="navbar-main navbar-middle">
                    <div className="container">
                        <div className="scroll-hide">
                            <div className="container">
                                <a className="navbar-brand center" href="#">
                                    <img className="logo-default" src="/vendor/images/logo.png" alt="logo" />
                                    <img className="logo-retina" src="/vendor/images/logo-retina.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                        <div className="navbar-header">
                            <a className="navbar-brand" href="">
                                <img className="logo-default" src="/vendor/images/logo.png" alt="logo" />
                                <img className="logo-retina" src="/vendor/images/logo-retina.png" alt="logo" />
                            </a>
                            <button type="button" className="navbar-toggle">
                                <i className="fa fa-bars"></i>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse">
                            <ul className="nav navbar-nav">
                                <li className="dropdown">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button">ACCUEIL <span className="caret"></span></a>
                                </li>

                                <li className="dropdown mega-dropdown mega-tabs">
                                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">Découvrez iKEM <span className="caret"></span></a>
                                </li>

                                <li className="dropdown">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button">NOS SOLUTIONS <span className="caret"></span></a>
                                    <ul className="dropdown-menu multi-level">
                                        <li><a href="#">ADJUVANTS POUR BÉTON</a></li>
                                        <li><a href="#">ADDITIFS POUR CIMENTS</a></li>
                                    </ul>
                                </li>

                                <li className="dropdown mega-dropdown mega-tabs">
                                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">Services & innovation <span className="caret"></span></a>
                                </li>

                                <li className="dropdown mega-dropdown mega-tabs">
                                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">Carrière <span className="caret"></span></a>
                                </li>

                                <li className="dropdown mega-dropdown mega-tabs">
                                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">Références <span className="caret"></span></a>
                                </li>

                            </ul>

                            <div className="nav navbar-nav navbar-right">
                                <div className="custom-area">
                                    <a href="#" className="btn btn-sm hidden-sm">Contactez-nous</a>
                                </div>
                                <form className="navbar-form" role="search">
                                    <div className="search-box-menu">
                                        <div className="search-box scrolldown">
                                            <input type="text" className="form-control" placeholder="Search for..." />
                                        </div>
                                        <button type="button" className="btn btn-default btn-search">
                                            <span className="fa fa-search"></span>
                                        </button>
                                    </div>
                                </form>
                                <ul className="nav navbar-nav lan-menu">
                                    <li className="dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button">EN</a>
                                        <ul className="dropdown-menu">
                                            <li><a>FR</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;