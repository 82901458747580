import React from 'react';
import { Parallax, Background } from 'react-parallax';

const Services = () => {

const paragraph_style = {
    textAlign: 'justify',
    fontWeight: 200,
    fontSize: '1.5rem',
    fontFamily: "'Coda', sans-serif"
    };

const image = "vendor/images/hd-15.jpg"; // Chemin vers votre image
const image_2 = "vendor/images/hd-16.jpg"; // Chemin vers votre image

  return (
    
    <div>

        <Parallax
            blur={0}
            bgImage={image}
            bgImageAlt="Description de l'image"
            strength={500}
        >
            <div className="section-two-blocks">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 bg-white">
                        <div className="content ">
                            <h2>Une innovation exceptionnelle</h2>
                            <p style={paragraph_style}>
                            La collaboration étroite avec nos partenaires clients demeure au cœur de notre approche chez iKEM. 
                            Nous nous investissons dans l'identification et la résolution des problématiques associées aux matériaux de construction, 
                            visant à transcender les barrières conventionnelles pour garantir des solutions innovantes et des avancées durables. 
                            Notre engagement se focalise sur la compréhension approfondie des enjeux spécifiques à chaque projet, 
                            afin d'apporter des réponses sur mesure qui maximisent non seulement la durabilité et la performance des matériaux, 
                            mais également l'efficacité globale du processus constructif. 
                            Cette approche collaborative vise à dépasser les attentes traditionnelles et à introduire des avancées significatives dans le secteur de la construction, 
                            façonnant ainsi un avenir bâti sur des bases de durabilité, d'innovation et de progrès continu.
                            </p>
                            <a href="#" className="btn btn-sm">EN SAVOIR plus SUR NOS INNOVATIONS</a>
                        </div>
                    </div>
                </div>
            </div>
        </Parallax>

        <Parallax
            blur={0}
            bgImage={image_2}
            bgImageAlt="Description de l'image"
            strength={500}
        >
            <div className="section-two-blocks blocks-right">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 bg-white">
                        <div className="content">
                            <h2>Un service de haut niveau</h2>
                            <p style={paragraph_style}>
                            La proposition de services innovants chez iKEM repose sur une approche orientée vers la personnalisation et l'adaptabilité. 
                            Nous concentrons nos efforts sur la mise en place de solutions sur mesure qui transcendent les besoins traditionnels de l'industrie. 
                            Notre engagement envers la digitalisation du secteur de la construction se traduit par une ingénierie de pointe et une agilité constante. 
                            Nous visons à créer des outils numériques évolutifs pour optimiser la qualité des matériaux, réduire les délais de livraison et répondre avec précision aux exigences spécifiques de chaque projet de construction. 
                            Cette approche innovante redéfinit les normes, ouvrant la voie à une nouvelle ère de pratiques constructives plus efficaces, 
                            durables et adaptées à l'évolution constante des demandes du marché.
                            </p>
                            <a href="#" className="btn btn-sm">EN SAVOIR PLUS SUR NOS SERVICES</a>
                        </div>
                    </div>
                </div>
            </div>
        </Parallax>

    </div>

  );
};

export default Services;
