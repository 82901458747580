import React from 'react';

const paragraph_style = {
    textAlign: 'justify',
    fontWeight: 200,
    fontSize: '2rem',
    fontFamily: "'Coda', sans-serif"
  };

const Slider = () => {
    return (
    <div>
        <div className="section-slider">
            <div className="flexslider slider white" data-options="animation:fade,controlNav:false,slideshowSpeed:4000">
                <ul className="slides">
                    <li>
                        <div className="bg-cover" style={{ backgroundImage: "url('vendor/images/hd-1.jpg')" }}></div>
                    </li>
                    <li>
                        <div className="bg-cover" style={{ backgroundImage: "url('vendor/images/hd-2.jpg')" }}></div>
                    </li>
                    <li>
                        <div className="bg-cover" style={{ backgroundImage: 'url("vendor/images/hd-3.jpg")' }}></div>
                    </li>
                </ul>
            </div>
            <div className="container content overlay-content white">
                <div className="row">
                    <div className="col-md-7">
                        <hr className="space" /><hr className="space m" />
                        <h1 className="text-uppercase">L'INNOVATION POUR LA CHIMIE DE LA CONSTRUCTION DURABLE</h1>
                        <p style={paragraph_style}>
                            iKEM précurseur incontesté dans le domaine de la chimie appliquée à la construction, s'engage pleinement dans la création et la mise en place de solutions novatrices ainsi que de services inédits visant à promouvoir la durabilité dans le secteur de la construction.
                        </p>
                        <hr className="space l" />
                        <div className="row">
                            <div className="col-md-4 col-slm-6 col-sm-6">
                                <a href="#" className="btn btn-sm btn-yellow nav-justified">Contactez-nous</a>
                            </div>
                            <div className="col-md-4 col-slm-6 col-sm-6">
                                <a href="#" className="btn btn-sm btn-border nav-justified">Nos produits</a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="space space-250" />
            </div>
        </div>

        
    </div>
    );
};

export default Slider;